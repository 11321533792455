@import 'styles/variables.scss';
@import 'styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');

html {
    // @media only screen and (min-width: 1024px) {
    //     font-size: 14px;
    // }

    // @media only screen and (min-width: 1366px) {
    //     font-size: 16px;
    // }

    @media only screen and (min-width: 2560px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 2880px) {
        font-size: 24px;
    }
}

html,
body,
#root {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    * {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #d1d1d1;
            border-radius: 10px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: hsl(210, 69%, 19%); //var(--text-color-primary)
            border-radius: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: hsl(210, 69%, 14%);
        }
        /* Handle on click */
        &::-webkit-scrollbar-thumb:active {
            background: hsl(210, 69%, 14%);
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: var(--font-size-xl);
}

h2 {
    font-size: var(--font-size-lg);
}

h3 {
    font-size: var(--font-size-md);
}

h4 {
    font-size: var(--font-size-sm);
}

h5 {
    font-size: var(--font-size-xs);
}

h6 {
    font-size: var(--font-size-xs);
}

p {
    font-size: var(--font-size-md);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    margin: 0.5em 0em;
    font-weight: 400;
}

ul {
    padding-inline-start: var(--padding-lg);
}

li {
    margin-bottom: 0.5em;
}

a {
    color: var(--text-color-blue);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: calc(100%);
        left: 50%;
        height: 1px;
        width: 0%;
        background: var(--text-color-blue);
        @include transition(300ms);
        @include transform(rotate(180deg));
        transform-origin: left;
    }
    &::after {
        content: '';
        position: absolute;
        top: calc(100%);
        left: 50%;
        height: 1px;
        width: 0%;
        background: var(--text-color-blue);
        @include transition(300ms);
    }

    &:hover {
        &::before {
            width: 50%;
        }
        &::after {
            width: 50%;
        }
    }
}

.ant-btn {
    border: none;
    box-shadow: none;
    font-size: var(--font-size-sm);
    height: auto;
    padding: var(--padding-sm) var(--padding-xl);
}

.ant-btn-link {
    padding: var(--padding-sm);
}

.ant-checkbox-wrapper {
    font-size: var(--font-size-sm);
}

.ant-drawer-header {
    background-color: var(--page-header-background-color);

    .ant-drawer-title {
        color: var(--text-color-primary);
    }
}

.ant-drawer-wrapper-body {
    background-color: var(--input-background-color);
}

.ant-drawer-header,
.ant-drawer-footer {
    border-color: var(--border-color);
}

.ant-input {
    font-size: var(--font-size-sm);
}

.ant-input-number-input-wrap {
    font-size: var(--font-size-sm);
}

.ant-picker-input > input {
    font-size: var(--font-size-sm);
}

.ant-select-selector {
    background-color: var(--input-background-color) !important;
    border-color: var(--border-color) !important;
    height: 44px !important;
    padding: 8px !important;
    padding-top: 6px !important;
}

.ant-select-item,
.ant-select-dropdown,
.ant-select-item-option-selected {
    background-color: var(--input-background-color) !important;
}

.ant-select-item,
.ant-select-item-option-selected,
.ant-select-selection-placeholder,
.ant-select-selection-item {
    color: var(--input-foreground-color) !important;
    font-size: var(--font-size-sm);
}

.ant-select-item-option-active {
    background-color: var(--input-background-color-disabled) !important;
    color: var(--input-foreground-color-disabled) !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    font-size: var(--font-size-sm);
    height: 2rem;
}

.ant-table {
    font-size: var(--font-size-sm);
}

.ant-table-tbody > tr > td {
    padding: var(--padding-lg);
}

.ant-tag {
    font-size: var(--font-size-sm);
    padding: var(--padding-xs) var(--padding-lg);
}

@media print {
    body * {
        visibility: hidden;
    }

    .printable,
    .printable * {
        visibility: visible;
    }

    .printable {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

textarea {
    background: var(--avena-input-background-color);
    border: none;

    &:focus-visible {
      outline: none;
    }
  }

:root {
    // fonts
    // there was a problem relating with overwriting the Poppins font. Temporarily I set up important here to display results properly.
    --avena-font-family: Poppins !important;
    // gradients
    --gradient-primary: linear-gradient(267.67deg, #1890ff 7.9%, #11a7a8 112.59%);
    --gradient-secondary: linear-gradient(268.54deg, #02c58a -39.69%, #11a7a8 3.44%, #257fd1 56.62%);
    --gradient-tertiary: linear-gradient(267.55deg, #1890ff 58.86%, #012d84 139.54%);
  }

