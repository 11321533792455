@mixin border-radius($i) {
    border-radius: $i;
    -webkit-border-radius: $i;
    -moz-border-radius: $i;
    -ms-border-radius: $i;
    -o-border-radius: $i;
}
@mixin transition($i) {
    transition: $i;
    -webkit-transition: $i;
    -moz-transition: $i;
    -ms-transition: $i;
    -o-transition: $i;
}
@mixin transform($i) {
    transform: $i;
    -webkit-transform: $i;
    -moz-transform: $i;
    -ms-transform: $i;
    -o-transform: $i;
}
@mixin animation($i) {
    animation: $i;
    -webkit-animation: $i;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin mobile {
    @media only screen and (max-width: 575px) {
        @content;
    }
}
