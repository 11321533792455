@import 'styles/mixins.scss';

.footer {
    background: #fbfbfb;
    margin-top: auto;

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .top_bar {
        align-items: center;
        justify-content: space-between;
        padding: var(--padding-xl) 0;

        .icon_links {
            gap: var(--margin-xxl);

            .group {
                gap: inherit;
                div {
                    color: #595959;
                    font-weight: 300;
                }

                a {
                    color: #595959;
                    font-weight: 300;
                }
            }
        }
    }

    .middle_bar {
        padding: 5vh 0;
        border-bottom: 1px solid #c7c7c7;

        .column {
            .section {
                margin-bottom: var(--margin-xxl);
                flex: 1;
                text-transform: capitalize;

                &:last-child {
                    margin: 0;
                }

                .contact {
                    align-items: center;
                    gap: 0.5rem;
                    color: #434343;

                    svg {
                        min-width: 18px;
                    }

                    a {
                        @extend .flex-center;
                        cursor: pointer;

                        &::before,
                        &::after {
                            display: none;
                        }

                        svg,
                        path {
                            @include transition(300ms);
                            transform-origin: center;
                        }

                        path {
                            transition-delay: 0ms;
                        }

                        &:hover {
                            svg {
                                @include transform(scale(1.3));
                                transition-delay: 0ms;
                            }
                        }

                        &:first-child {
                            svg {
                                transition-delay: 300ms;
                            }

                            &:hover {
                                svg {
                                    @include transform(scale(1.5));
                                    transition-delay: 0ms;

                                    path:first-child {
                                        @include transform(rotate(360deg));
                                        transition-delay: 300ms;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            h3 {
                color: #262626;
                font-weight: 500;
                font-size: var(--font-size-md);
                margin-bottom: var(--margin-lg);
            }

            .links {
                gap: var(--margin-md);
                a {
                    font-weight: 200;
                    color: #262626;
                    width: fit-content;
                    @include transition(300ms);

                    &::before,
                    &::after {
                        background: #262626;
                    }
                }

                svg {
                    cursor: pointer;
                    path,
                    line {
                        fill: #000000d9;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .section {
            align-items: center;
            text-align: center;
        }

        .middle_bar .column .links {
            align-items: center;
        }
    }

    @media (max-width: 767px) {
        .top_bar {
            gap: 10%;
            .icon_links {
                flex-direction: column;
                row-gap: var(--margin-sm);
                column-gap: var(--margin-md);
                width: 60%;

                .group div {
                    flex: 1;
                    white-space: nowrap;
                }
            }
        }
        .middle_bar {
            text-align: center;

            .contact {
                justify-content: center;
            }
        }
    }

    @media (max-width: 575px) {
        .top_bar {
            svg {
                display: none;
            }

            .icon_links {
                column-gap: var(--margin-xxl);
            }
        }
    }
}
