@import 'styles/mixins.scss';

.header {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #0000001a;
    height: 91px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;

    button {
        width: 112px;
        height: 45px;
    }

    .header_content {
        width: 100%;
        height: 91px;
        display: flex;
        align-items: center;
        z-index: 88;

        .link_button {
            font: var(--avena-font-m-regular);
            color: var(--avena-accent-color-primary);
            margin-right: 30px;
        }

        svg {
            position: relative;
            bottom: 8px;
        }

        .menu {
            margin-left: 62px;
            gap: var(--margin-md);

            a {
                color: var(--text-color-primary);
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 var(--padding-md);
                cursor: pointer;

                &::before,
                &::after {
                    background: var(--text-color-primary);
                }
            }

            .active_link {
                color: var(--button-background-color-blue);
                pointer-events: none;
            }
        }
    }

    .hamburger {
        width: 35px;
        height: 35px;
        @include border-radius(var(--border-rounded));
        background: #fff;
        position: relative;
        cursor: pointer;
        display: none;

        span {
            width: 24px;
            height: 2px;
            background: #121212;
            position: absolute;
            top: 50%;
            left: 5px;
            @include transform(translateY(-50%));
            @include transition(300ms);

            &:first-child {
                width: 18px;
                height: 2px;
                background: #121212;
                position: absolute;
                top: 10px;
            }

            &:last-child {
                width: 14px;
                height: 2px;
                background: #121212;
                position: absolute;
                top: 27px;
            }
        }
    }
}

.mobile_menu {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    @include transform(translateY(-100%));
    top: 91px;
    left: 0;
    z-index: 999999;
    @include transition(500ms);
    position: fixed;
    width: 100%;
    background: #fff;
    padding: var(--padding-md) 0 var(--padding-lg);
    box-shadow: 0px 4px 4px 0px #0000001a;
    display: none;

    .vstack {
        margin-top: var(--margin-md);
        div {
            text-align: center;

            a {
                position: relative;
                color: var(--text-color-primary);
                text-decoration: none;

                &::before,
                &::after {
                    background: var(--text-color-primary);
                }
            }

            .active_link {
                color: var(--button-background-color-blue);
                pointer-events: none;
            }
        }
    }

    button {
        width: fit-content;
        margin: auto;
    }
}

.mobile_menu.active {
    @include transform(translateY(0));
    max-height: 200vh;
    opacity: 1;
    pointer-events: visible;
}

@media (max-width: 991px) {
    .header {
        .header_content {
            svg {
                bottom: 0;
            }
            .menu {
                display: none;

                & + div + button,
                & + div + button + button,
                & + div + button + button + a {
                    display: none;
                }
            }
        }

        .link_button {
            margin-right: 12px !important;
            font: var(--avena-font-s-regular) !important;
        }

        .button {
            display: none;
        }

        .hamburger {
            display: block;
        }

        .hamburger.active {
            span {
                &:nth-child(2) {
                    left: 100%;
                    opacity: 0;
                }

                &:first-child {
                    width: 26px;
                    top: 50%;
                    transition-delay: 100ms;
                    @include transform(rotate(45deg));
                }

                &:last-child {
                    width: 26px;
                    top: 50%;
                    transition-delay: 100ms;
                    @include transform(rotate(-45deg));
                }
            }
        }
    }

    .mobile_menu {
        display: flex;
    }
}
