.go_to_top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: rgba($color: #fff, $alpha: 0.3);
    border: 0.4px solid var(--text-color-primary);
    color: var(--text-color-primary);
    width: 2rem;
    height: 2rem;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    z-index: 9999999;
}
